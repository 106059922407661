<template>
  <b-container fluid>
    <b-row>
      <b-col type="dark" variant="custom" class="top-menu pt-0 mt-0 mb-1" style="background-color: steelBlue; color:white">
       <b-navbar toggleable="lg" type="dark" class="ms-auto mb-2 mb-lg-0 pt-0 mt-0" variant="custom" right>
           
          <b-navbar-brand>
            <BLink :href="nasa.url" class="logo">
              <b-img :src="nasa.src" :alt="nasa.alt" style="height: 60px" />
            </BLink>
            <BLink href="/" class="logo" style="color:white; font-size:0.7em"> 
              <b-img fluid :src="smalllogo.src" :alt="smalllogo.alt" style="height: 50px; margin-right:10px" />
              <br/>{{smalllogo.alt}}
            </BLink>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse" right></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav right>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto" type="dark" variant="custom" right>

              <b-nav-item v-for="item in this.menu()" v-bind:key="item.displayName" :to="item.path" right>{{ item.displayName }}</b-nav-item>

              <b-nav-item class="pr-3" :href="apiURL" right>API</b-nav-item>

              <b-nav-item-dropdown v-if="isLoggedIn()" :text="'Welcome, ' + this.userLabel()" class="welcome" variant="custom" right>
                <b-dropdown-item :href="this.urls.urs.profile" target="_blank">Earthdata Profile</b-dropdown-item>
                <b-dropdown-item @click="onLogout(false)">Log out</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item v-else :href="urls.dynamicLogin" class="log-in" right>
                Log In <font-awesome-icon icon="sign-in-alt"/>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import authMixins from '@/components/app/authMixins'
import { useUserStore } from '../../store/user-store.js'
import { storeToRefs } from 'pinia'

export default {

  name: 'AppHeader',

  mixins: [ authMixins ],

  data () {
    return {
      apiURL: process.env.VUE_APP_API_URL,
      isLoggedIn: () => ( useUserStore().isLoggedIn ),
      userLabel: () =>  ( useUserStore().first_name )
      
    }
  },

}

</script>

<style scoped>

/* requires use of ::v-deep() to reach "deep" into child component */
.welcome ::v-deep(.btn-custom) {
  color: white;
  background-color: steelBlue;
}

.welcome ::v-deep(.btn-custom:hover) {
  color: white;
}

.navbar{
  padding-bottom: 0px;
}

.top-menu ::v-deep(a) > img { 
  margin-right: 0px;
}

.top-menu ::v-deep(a) { 
  color: rgba(255, 255, 255,1);
  text-decoration: none;
  font-weight: 800;
}

.top-menu ::v-deep(a:hover) { 
  color: rgba(255, 255, 255, 0.8)
}

.nav-item ::v-deep(a) {
  margin-right: 15px;
}

.navbar-nav {
  --bs-nav-link-padding-y: 0rem;
}
</style>
