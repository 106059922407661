export const consts = {

  LOGO: {
    src: require('@/assets/img/logos/TOLNet_Logo.png'),
    alt: "Tropospheric Ozone Lidar Network (TOLNet)"
  },

  SMALLLOGO: {
    src: require('@/assets/img/logos/TOLNet_Logo_small.png'),
    alt: "Tropospheric Ozone Lidar Network"
  },

  NASALOGO: {
    src: require('@/assets/img/logos/nasa.png'),
    alt: "NASA",
    url: "https://nasa.gov"
  },

  URLS: {
    ident: process.env.VUE_APP_API_URL + '/auth/ident',
    login: process.env.VUE_APP_API_URL + '/auth/login',
    logout: process.env.VUE_APP_API_URL + '/auth/logout',
    documentDownload: process.env.VUE_APP_API_URL + '/document/download',
    dataDownload: process.env.VUE_APP_API_URL + '/data/download',
    urs: {
      profile: process.env.VUE_APP_URS_URI + '/profile',
      newUser: process.env.VUE_APP_URS_URI + '/users/new',
      logout: process.env.VUE_APP_URS_URI + '/logout'
    },
    // omt: { // Open Map Tiles
    //   klokantechBasic: process.env.VUE_APP_OMT_URL + '/styles/klokantech-basic/{z}/{x}/{y}.png',
    //   osmBright: process.env.VUE_APP_OMT_URL + '/styles/osm-bright/{z}/{x}/{y}.png',
    //   positron: process.env.VUE_APP_OMT_URL + '/styles/positron/{z}/{x}/{y}.png'
    // },

    contactUs: '/contact-us',
    support: 'mailto:support-asdc@earthdata.nasa.gov',
    dataIDs: 'https://www-air.larc.nasa.gov/missions/etc/IcarttDataFormat.htm#4'
  },

  STATIONS: {
    ABOUT: {
      name: "About TOLNet",
      graphic1: require('@/assets/img/home/OWLETS_Group_Picture.jpg'),
      graphic1Alt: "Group photo of the TOLNet and OWLETS team, about 40 people.",
    },
    UAH: {
      name: "UAH",
      graphic1: require('@/assets/img/home/UAH/UAH-ozone-lidar-measurements.png'),
      graphic1Alt: "Figure 1. UAH ozone lidar and ozonesonde measurements compared to NOAA WP-3D airborne ozone observation on 29 June 2013 during the SENEX field campaign.",
      graphic2: require('@/assets/img/home/UAH/UAH-ozone-lidar.png'),
      graphic2Alt: "Figure 2. Configuration schematic for the UAH ozone lidar.",
    },
    LARC: {
      name: "NASA LaRC",
      graphic1: require('@/assets/img/home/LaRC/lmol-ozone-curtain.png'),
      graphic1Alt: "Figure 1. Example LMOL data, obtained during the OWLETS-1 campaign along with ozonesonde, UAV, and in-situ surface results.",
      graphic2: require('@/assets/img/home/LaRC/data-on-phones.png'),
      graphic2Alt: "Figure 2. LMOL data is displayed real-time on the internet for larger science team benefit during home base and field operations.",
      graphic3: require('@/assets/img/home/LaRC/lmol-system.png'),
      graphic3Alt: "Figure 3. Photograph of the LMOL system deployed at Hart-Miller Island.",
      graphic4: require('@/assets/img/home/LaRC/lmol-system-diagram.png'),
      graphic4Alt: "Figure 4. LMOL system diagram.",
    },
    GSFC: {
      name: "NASA GSFC",
      graphic1: require('@/assets/img/home/GSFC/GSFC TROPOZ DIAL hardware design.png'),
      graphic1Alt: "Figure 1. The hardware design of the GSFC TROPOZ DIAL depicting the transmitter, Raman cells, detectors, and data acquisition system.",
      graphic2: require('@/assets/img/home/GSFC/GSFC TROPOZ DIAL deployed.png'),
      graphic2Alt: "Figure 2. The GSFC TROPOZ DIAL was deployed to Ft. Collins, CO during the 2014 DISCOVER AQ campaign. The NASA P3B is shown spiraling over the site while the TROPOZ is taking measurements. Ozonesondes were also launched at the site to help validated the aircraft and lidar profiles.",

    },
    JPL: {
      name: "NASA JPL",
      graphic1: require('@/assets/img/home/JPL/ACOM-WRF-Chem-model.png'),
      graphic1Alt: "Evaluation of the ACOM WRF-Chem model forecast over Table Mountain Facility (upper panel) based on TMTOL measurements (lower panel) during the FIREX-AQ campaign.",
      graphic2: require('@/assets/img/home/JPL/JPL_block_diagram.png'),
      graphic2Alt: "Block diagram of TMTOL",
    },
    AMES: {
      name: "NASA AMES",
      graphic1: require('@/assets/img/home/Ames/TOLNet O3 retrievals.png'),
      graphic1Alt: "Figure 1. TOLNet O<sub>3</sub> retrievals used to determine the importance of a prior information used in TEMPO tropospheric O<sub>3</sub> retrievals. Vertical profiles of a prior (solid line) and retrieved (dashed line) O<sub>3</sub> profiles when applying a climatology product (red) and a CTM (blue) as a priori information in TEMPO retrievals compared to TOLNet (black).",
      graphic2: require('@/assets/img/home/Ames/Siberian wildfire pollution.png'),
      graphic2Alt: "Figure 2. Observations of Siberian wildfire pollution in western Canada. (a) AMOLITE and GEOS-CF O<sub>3</sub> (ppb) and (b) AMOLITE Bo (sr<sup>-1</sup>Mm<sup>-1</sup> @ 532 nm) and GEOS-CF PM2.5 (µg m<sup>-3</sup>, log scale) from July 28–August 4, 2019. The black box highlights the free troposphere (3–8 km agl) layer and the red box highlights the lower free troposphere (3–5.5 km agl) layer."
    },
    NOAACSL: {
      name: "NOAA CSL",
      graphic1: require('@/assets/img/home/NOAACSL/diagram-of-topaz-lidar.png'),
      graphic1Alt: "Figure 1. Schematic diagram of the TOPAZ lidar",
      graphic2: require('@/assets/img/home/NOAACSL/truck-mounted-topaz-ozone-lidar.png'),
      graphic2Alt: "Figure 2. Trunk-mounted TOPAZ ozone lidar with roof-top, two axis scanner"
    },
    ESRL: {
      name: "NOAA ESRL",
    },
    ECCC: {
      name: "ECCC",
      graphic1: require('@/assets/img/home/ECCC/AMOLITE.png'),
      graphic1Alt: "The mobile lidar system AMOLITE in the snow",
      graphic2: require('@/assets/img/home/ECCC/30-day-ozone-plot.png'),
      graphic2Alt: "30 day continuous ozone plot showing ranges from 0 to 175 ppbv over 3 to 13 km"
    },
    CCNY: {
      name: "CCNY",
      graphic1: require('@/assets/img/home/CCNY/Picture1.jpg'),
      graphic1Alt: "Figure 1. CCNY Tropospheric Ozone DIAL schematic diagram",
      graphic2: require('@/assets/img/home/CCNY/Picture2.png'),
      graphic2Alt: "Figure 2. Time-height distribution of ozone observed by CCNY Ozone DIAL on June 25, 2022",
    },
    HAMPTONU: {
      name: "Hampton University",
      graphic1: require('@/assets/img/home/HAMPTONU/Picture1.jpg'),
      graphic1Alt: "Figure 1. HU Lidar Instrument.",
    },
  },

  PARTNERS: [
    {
      name: "NASA",
      logo: require('@/assets/img/logos/nasa.png'),
      url: "https://nasa.gov"
    },
    {
      name: "NOAA",
      logo: require('@/assets/img/logos/noaa.png'),
      url: "https://www.noaa.gov"
    }
  ]
}
