import { createRouter, createWebHistory } from 'vue-router'
import AppHome from './views/AppHome.vue'

export const router = createRouter({ 
  history: createWebHistory(),
  

  base: process.env.BASE_URL,

  //scrollBehavior: () => ({ top: 0 }),
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { x:0, y:0 }
  },

  routes: [
    {
      path: '/',
      name: 'Home',
      displayName: 'Home',
      loginRequired: false,
      component: AppHome,  // load on import

    },
    {
      path: '/calendar',
      name: 'Calendar',
      displayName: 'Data Calendar',
      loginRequired: false,
      component: () => import('./views/AppGraph.vue'),  // lazy load
    },
    {
      path: '/download',
      name: 'Download',
      displayName: 'Download',
      loginRequired: false,
      component: () => import('./views/AppDownload.vue'),  // lazy load
    },
    {
      path: '/publications',
      name: 'Publications',
      displayName: 'Publications',
      loginRequired: false,
      component: () => import('./views/AppPublications.vue'),  // lazy load
    },
    {
      path: '/team-area',
      displayName: 'Team Area',
      loginRequired: true,
      component: () => import('./views/AppTeamArea.vue'),  // lazy load
      children: [
        // Note we provide the above parent route name on the default child tab
        // route to ensure this tab is rendered by default when using named routes
        { path: '', component: () => import('./components/team-area/TeamDocuments'),  name: 'Team Area' },
        { path: 'upload', component: () => import('./components/team-area/UploadData'), name: 'upload-data'  },
        { path: 'upload-raw', component: () => import('./components/team-area/UploadRawData'), name: 'upload-raw-data'  },
       // { path: 'meetings', component: () => import('./components/team-area/TeamMeetings'), name: 'team-meetings'  }
      ]
    },
    {
      path: '/admin-area',
      name: 'Admin',
      displayName: 'Admin',
      loginRequired: true,
      component: () => import('./views/AppAdminArea.vue'),  // lazy load
    },
    {
      path: '/contact-us',
      name: 'Contact Us',
      displayName: 'Contact Us',
      loginRequired: false,
      component: () => import('./views/AppContactUs.vue'),  // lazy load
    },

  ]
})

// router.resolve({
//   name: 'not-found',
//   params: { pathMatch: ['not', 'found'] },
//   // TODO: make 404 page
// }).href

//TODO: set timer to alert user prior to expiration and implement auto-logout

// if the user is not logged-in, do not allow deep linking: redirect to /;
// else, allow passage; store.user is not reliable at this stage or beforeResolve

// router.beforeEach( (to,from,next) => {
//   if ( ! to.loginRequired ) next()
//   else if ( ! to.name || ! to.children) next('/')
//   else api.get(consts.URLS.ident)  // validate w/ server as guard
//           .then( response => next() )
//           .catch( error => next('/') )
//   })
