/*
 * Author: Damian Gessler, Semantic Options, LLC
 *
 * Based on prior work by Amanda Benson Early and Aubrey L. Beach, III
 *
 */

/* imports */
import { createPinia } from 'pinia';
import { createApp, watch } from 'vue'
import BootstrapVueNext from 'bootstrap-vue-next'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import './init/init'
import App from './App.vue'
import { router } from './router.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

app.use(BootstrapVueNext)
app.use(router)

const pinia = createPinia()
app.use(pinia)

watch(
    pinia.state,
    (state) => {
      // persist the whole state to the local storage whenever it changes
      localStorage.setItem('piniaState', JSON.stringify(state))
    },
    { deep: true }
  )

app.mount('#app')
