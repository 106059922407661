import { api, consts, eventBus } from '@/init/init'
import { useUserStore } from '../../store/user-store.js'

export default {

  name: "authMixins",

  methods: {

    // due to CORS header handling, do not use a onLogin() method; use :href="urls.dynamicLogin"
    onLogout(logoutURS) {
      api.get(this.urls.logout)
          .then( response => { } )
          .catch( error => { console.error( error ) } )
          .finally( () => {
            useUserStore().$reset()
            if ( logoutURS ) {
              // api.get(this.urls.urs.logout)
              //    .then( response => {} )
              //    .catch( error => {} )

              // we can no longer just call the URS logout url
              // becuase the browser won't complete the call due to CORS policy
              // This has been brought up with the URS folks,
              // but for now, we will just send the user to the URS logout page
              // which will in turn, send them back to the current page.
              // In theory, the user won't notice any difference, just a page load flicker.
              window.open(this.urls.urs.logout + '?response_type=code&client_id=' + process.env.VUE_APP_URS_KEY + '&redirect_uri=http%3A%2F%2F' + encodeURIComponent(location.host) + encodeURIComponent(this.$route.path) , "_self")

            }
            this.forceRerender();
          })

      // rerender the page, since they now might no longer have access to what they were seeing
      this.$forceUpdate();
    },
    
    forceRerender() {
      eventBus.emit("logout-clear-documents");
    }
  },

  created () {
    this.urls.dynamicLogin = this.urls.login + '?redirect=' + window.location.href.replace(/^.*\/\/[^\/]+/, '')

    this.menu = function () {
      // only include items in the route that has a name
      var modified_routes = [];
      for (var i in this.$router.options.routes) {
        if (!this.$router.options.routes.hasOwnProperty(i)){
          continue
        }

        var route = this.$router.options.routes[i];

        if(route.hasOwnProperty('displayName') && ((route.displayName != 'Admin') || useUserStore().isAdmin )) {

          modified_routes.push(route);
        }

      }
      return modified_routes;
    }
  },

  data () {
    return {
      urls: consts.URLS,
      logo: consts.LOGO,
      smalllogo: consts.SMALLLOGO,
      nasa: consts.NASALOGO,
      stations: consts.STATIONS,
      route: this.$router,
    }
  },

  watch: {
    route: {
      handler(to, from) {
        this.urls.dynamicLogin = this.urls.login + '?redirect=' + to.currentRoute.path
      },
      deep: true
    },
  },

}
