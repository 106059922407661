// init a global <font-awesome-icon> directive


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


// import individual icons

// sample use: "solid" icons
// <font-awesome-icon icon="user-circle"/>  icon, not :icon
import {
  faCaretDown,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faCircle,
  faDownload,
  faExternalLink,
  faEnvelope,
  faImage,
  //faExclamationCircle,
  //faGlobe,
  faGlobeAmericas,
  faInfoCircle,
  faListUl,
  faPlane,
  //faPlaneArrival,
  //faPlaneDeparture,
  //faRedo,
  //faRedoAlt,
  faSearch,
  faSearchLocation,
  faSignInAlt,
  faSort,
  //faSync,
  faTimes,
  faTimesCircle,
  //faToggleOn,
  //faToggleOff,
  faUserCircle,
  faUpload,
  faUsers,
  faCopy,
  faChevronRight,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faChartLine,
  faComments,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'

// add them to the library
library.add(
  faCaretDown,
  faCaretRight,
  faCheck,
  faCheckCircle,
  faCircle,
  faDownload,
  faExternalLink,
  faEnvelope,
  faImage,
  //faExclamationCircle,
  //faGlobe,
  faGlobeAmericas,
  faInfoCircle,
  faListUl,
  faPlane,
  //faPlaneArrival,
  //faPlaneDeparture,
  //faRedo,
  //faRedoAlt,
  faSearch,
  faSearchLocation,
  faSignInAlt,
  faSort,
  //faSync,
  faTimes,
  faTimesCircle,
  //faToggleOn,
  //faToggleOff,
  faUserCircle,
  faUpload,
  faUsers,
  faCopy,
  faChevronRight,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faChartLine,
  faComments,
  faTriangleExclamation,
)

// sample use: "regular" (outline) icons;
// note: dynamically bind with :icon, not icon;
//       do not add -regular; e.g., 'user' not 'user-regular'
// <font-awesome-icon :icon="['far', 'user']" size="8x"/>
import {
  faCheckCircle as faCheckCircleRegular,
  faCircle as faCircleRegular,
  faCompass as faCompassRegular,
  faDotCircle as faDotCircleRegular,
  faSquare as faSquareRegular,
  faTimesCircle as faTimesCircleRegular,
  faUser as faUserRegular,
  faComments as faCommentsRegular,

} from '@fortawesome/free-regular-svg-icons'

library.add(
  faCheckCircleRegular,
  faCircleRegular,
  faCompassRegular,
  faDotCircleRegular,
  faSquareRegular,
  faTimesCircleRegular,
  faUserRegular,
  faCommentsRegular
)

