<template>
  <b-container>
    <div class="d-flex flex-wrap justify-content-center align-items-center text-muted">
      <a target="_blank" href="https://www.nasa.gov/about/highlights/HP_Privacy.html">Web Privacy</a>
      <font-awesome-icon icon="circle"/>
      <a target="_blank" href="https://www.earthdata.nasa.gov/engage/open-data-services-and-software/data-and-information-policy">Data &amp; Information Policy</a>
      <font-awesome-icon icon="circle"/>
      <a target="_blank" href="https://www.nasa.gov/accessibility/">Accessibility</a>
      <font-awesome-icon icon="circle"/>
      <a target="_blank" href="https://www.nasa.gov/audience/formedia/features/communication_policy.html">Communications Policy</a>
      <font-awesome-icon icon="circle"/>
      <a target="_blank" href="https://www.nasa.gov/FOIA/index.html">Freedom of Information Act</a>
      <font-awesome-icon icon="circle"/>
      <a target="_blank" href="https://www.usa.gov/">USA.gov</a>
    </div>
    <div class="d-flex flex-wrap justify-content-center align-items-center text-muted">
      <span><strong>NASA Official:</strong> Michael Shook</span>
      <font-awesome-icon icon="circle"/>
        <span><strong>Site Curator:</strong> TOLNet Webmaster</span>
        <font-awesome-icon icon="circle"/>
      <span class="text-muted wrapper"><strong>Last Updated:</strong> {{ lastUpdated }}</span>
    </div>
  </b-container>
</template>

<script>

// import { store } from '@/store/store'

export default {

  name: 'AppFooter',

  created () {
    this.lastUpdated = process.env.VUE_APP_LAST_UPDATED
  }

}

</script>

<style scoped>

.container {
  padding: 30px;
  text-align: center;
}

span, a {
  font-size: small;
}

a {
  color: #0056b3
}

.fa-circle {
  font-size: 30%;
  margin: 10px;
}

</style>
