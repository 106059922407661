import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    /** @type {{
      uid: string,
      email: string,
      isLoggedIn: boolean,
      isAdmin: boolean,
      canUploadDocuments,
      canDownloadNearRealTimeData,
      canUploadData,
      first_name: string,
      last_name: string,
      expiresOn: int (unix (epoch) timestamp)
      }}
    **/
      uid: null,
      isLoggedIn: false,
      isAdmin: false,
      canUploadDocuments: false,
      canDownloadNearRealTimeData: false,
      canUploadData: false,
      first_name: null,
      last_name: null,
      expiresOn: 0
  }),
  actions: {
    // any amount of arguments, return a promise or not
    update(jsonObjectFromAPI) {
      // you can directly mutate the state
      this.uid = jsonObjectFromAPI.uid;
      this.email = jsonObjectFromAPI.email;
      this.isAdmin = jsonObjectFromAPI.isAdmin;
      this.canUploadDocuments = jsonObjectFromAPI.canUploadDocuments;
      this.canDownloadNearRealTimeData = jsonObjectFromAPI.canDownloadNearRealTimeData;
      this.canUploadData = jsonObjectFromAPI.canUploadData;
      this.first_name = jsonObjectFromAPI.first_name;
      this.last_name = jsonObjectFromAPI.last_name;
      this.expiresOn = jsonObjectFromAPI.expiresOn;
      this.uid = jsonObjectFromAPI.uid;

      if (this.uid !== null) {
        this.isLoggedIn = true;
      }
    },
    clear() {
      this.uid = null;
      this.isLoggedIn = false;
      this.isAdmin = false;
      this.canUploadDocuments = false;
      this.canDownloadNearRealTimeData = false;
      this.canUploadData = false;
      this.first_name = null;
      this.last_name = null;
      this.expiresOn = 0;
    },

  },
})