<script setup>
  import { storeToRefs } from 'pinia'
  import { useUserStore } from './store/user-store.js'

  const userStore = useUserStore()
  userStore.$subscribe((mutation, state) => {})
</script>

<template>
  <div id="TOLNetWebsite">
    <app-header/>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component">
        </component>
      </keep-alive>
    </router-view>
    <app-footer/>
  </div>
</template>

<script>

import { api } from '@/init/init'
import AppHeader from '@/components/app/AppHeader'
import AppHome from '@/views/AppHome'
import AppFooter from '@/components/app/AppFooter'
import authMixins from '@/components/app/authMixins'

export default {

  name: 'App',

  components: {
    AppHeader,
    AppHome,
    AppFooter
  },

  mixins: [ authMixins ],

  methods: {
    // TODO: TRY AND PUT async before this method
    ident () {
      api.get(this.urls.ident)
          .then( response => { useUserStore().update(response.data) } )
          .catch ( error => {  } )  // 401 expected if user is not logged-in
    }
  },

  created () {
    this.ident()
  },

}

</script>

<style>  /* global, not scoped */

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
