<template>
  <b-container fluid>
    <b-row align-v="center"  class="text-center cards">
      <b-col cols="12" sm="4" md="4" lg="4" class="p-0" >
      
          <b-link class="text-dark equal-width" to="download">
            <b-card title="Download" bg-variant="light" border-variant="light" >
              <font-awesome-icon icon="download" size="5x"/>
              <hr>
              <p class="card-text">
                Browse and<br />download data
              </p>
            </b-card>
          </b-link>

      </b-col>
      <b-col cols="12" sm="4" md="4" lg="4" class="p-0" >

          <b-link class="text-dark equal-width" to="publications">
            <b-card title="Publications" bg-variant="light" border-variant="light" >
              <font-awesome-icon icon="copy" size="5x"/>
              <hr>
              <p class="card-text">
                Find and cite<br />publications
              </p>
            </b-card>
          </b-link>

      </b-col>
      <b-col cols="12" sm="4" md="4" lg="4" class="p-0" >

          <b-link class="text-dark equal-width" to="team-area">
            <b-card title="Team Area" bg-variant="light" border-variant="light">
              <font-awesome-icon icon="users" size="5x"/>
              <hr>
              <p class="card-text">
                Documentation, Data<br />Upload, and Meetings
              </p>
            </b-card>
          </b-link>

      
      </b-col>
    </b-row>

  </b-container>
</template>

<script>

import { consts, store } from '@/init/init'

export default {

  name: 'InfoRow',

}
</script>

<style scoped>

.equal-width {
  width:25%;
}

.container {
  text-align: center;
}

.cards ::v-deep(a) {
  text-decoration-line: none;
}

.cards ::v-deep(a:hover) {
  text-decoration-line: underline;
}

/* fontawesome icons */
.fa-download, .fa-upload, .fa-users, .fa-copy {
  color: steelBlue;
}

</style>
