<template>
  <b-container fluid>
    <b-row align-v="center" >
      <b-col cols="3" sm="3" md="3" lg="3" >
        <b-img fluid :src="logo.src" :alt="logo.alt"/>
      </b-col>
      <b-col cols="9" sm="9" md="9" lg="9" >
        <h1>TOLNet - Tropospheric Ozone Lidar Network</h1>
        Ground-based Profiling of Tropospheric Ozone<br/><br/>

        <strong>Project Objectives</strong><br/>
        <p>
          TOLNet was established in 2012 to provide high spatio-temporal observations of tropospheric ozone to (1) better understand physical processes driving the ozone budget in various meteorological and environmental conditions, and (2) validate the tropospheric ozone measurements of spaceborne missions.
        </p>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>

import authMixins from '@/components/app/authMixins'

export default {

  name: 'SiteIntro',

  mixins: [ authMixins ],


}
</script>

<style scoped>

.card {
  text-align: center;
}

.card-text {
  padding: 10px;
}

/* fontawesome icons */
.fa-user, .fa-user-circle {
  color: steelBlue;
}

.urs-logout {
  color: steelBlue;
  display: block;
  font-size: 75%;
  margin-top: 10px;
}

</style>
